var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"light":"","persistent":"","max-width":"700"},model:{value:(_vm.licenseFormDialog),callback:function ($$v) {_vm.licenseFormDialog=$$v},expression:"licenseFormDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline px-2 pb-0",staticStyle:{"border-bottom":"1px solid #ccc"}},[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[_c('v-toolbar-title',{staticClass:"text-h5 pl-0",staticStyle:{"height":"45px"}},[_vm._v(" Nova solicitação ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"red","fab":"","icon":"","dark":"","x-small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleLicenseFormDialog}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-form',{ref:"licenseForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"mt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.$store.state.formRules.required,"label":"Empresa","required":""},model:{value:(_vm.formData.requesting_company),callback:function ($$v) {_vm.$set(_vm.formData, "requesting_company", $$v)},expression:"formData.requesting_company"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.$store.state.formRules.emailRules,"label":"Email","required":""},model:{value:(_vm.formData.requesting_email),callback:function ($$v) {_vm.$set(_vm.formData, "requesting_email", $$v)},expression:"formData.requesting_email"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',[_c('v-text-field-simplemask',{attrs:{"label":`CPF/CNPJ`,"properties":{
                                                rules: [
                                                    _vm.$store.state.formRules.cpfCnpjRules[0](
                                                        _vm.formData.document ? _vm.formData.document : ''
                                                    ),
                                                ],
                                                prefix: '',
                                                suffix: '',
                                                readonly: false,
                                                disabled: _vm.$store.state.clientPermissions.subscriberJustView,
                                                outlined: false,
                                                clearable: false,
                                                placeholder: '',
                                            },"options":{
    inputMask:
        (_vm.formData.document
            ? _vm.formData.document.length
            : '') <= 11
            ? '###.###.###-########'
            : '##.###.###/####-##',
    outputMask: '##############',
    empty: null,
    applyAfter: false,
    alphanumeric: true,
    lowerCase: false,
}},model:{value:(_vm.formData.document),callback:function ($$v) {_vm.$set(_vm.formData, "document", $$v)},expression:"formData.document"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"rules":_vm.$store.state.formRules.required,"label":"Quantidade de licenças","required":""},model:{value:(_vm.formData.qtd),callback:function ($$v) {_vm.$set(_vm.formData, "qtd", $$v)},expression:"formData.qtd"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.operationsList,"label":"Operação"},model:{value:(_vm.formData.operation_type),callback:function ($$v) {_vm.$set(_vm.formData, "operation_type", $$v)},expression:"formData.operation_type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.typeLicenseList,"label":"Tipo de licença"},model:{value:(_vm.formData.licence_id),callback:function ($$v) {_vm.$set(_vm.formData, "licence_id", $$v)},expression:"formData.licence_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"maxlength":"8","rules":_vm.$store.state.formRules.required,"label":"Ordem","required":""},model:{value:(_vm.formData.order),callback:function ($$v) {_vm.$set(_vm.formData, "order", $$v)},expression:"formData.order"}})],1)],1)],1)],1)],1),_c('v-card-actions',{staticClass:"px-0 mx-3",staticStyle:{"border-top":"1px solid rgb(220, 220, 220)"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":_vm.toogleLicenseFormDialog}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"green","text":""},on:{"click":_vm.saveLicense}},[_vm._v(" aplicar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }