import { render, staticRenderFns } from "./ShowLicensesDialog.vue?vue&type=template&id=00a2e922&scoped=true"
import script from "./ShowLicensesDialog.vue?vue&type=script&lang=js"
export * from "./ShowLicensesDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a2e922",
  null
  
)

export default component.exports